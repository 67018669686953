import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { graphql } from 'gatsby';
import LawPage from '../components/LawPage';

const LawIPPage: React.FC = () => (
  <Layout id="lawIP">
    <SEO title="IPLaw" />
    <LawPage
      law="LawIntellectual"
      title="fieldLawIP"
      serviceTitle="fieldLawIPTitle"
      serviceTitleTwo="fieldLawIPTitleTwo"
      lawTexts={[
        'fieldLawIPOne',
        'fieldLawIPTwo',
        'fieldLawIPThree',
      ]}
    />
  </Layout>
);

export default LawIPPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
